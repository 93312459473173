export const CATEGORY_ICONS = {
  Management: 6,
  "Information Technology": 1,
  "Arts & Humanities": 2,
  Commerce: 3,
  "Media & Journalism": 4,
  "Amity Future Academy": 1,
  Science: 5,
  "Amity PROx": 5,
  "Free Courses": 5,
};

export const PROGRAM_DROPDOWN_LP = [
  {
    title: "BACHELOR OF BUSINESS ADMINISTRATION",
    programType: "UG",
    id: "240",
  },
  {
    title: "BACHELOR OF COMPUTER APPLICATIONS",
    programType: "UG",
    id: "241",
  },
  {
    title: "MASTER OF BUSINESS ADMINISTRATION",
    programType: "PG",
    id: "440",
  },
  {
    programType: "PG",
    title: "MASTER OF COMPUTER APPLICATIONS",
    id: "515",
  },
]

export const DEGREE_TYPES = {
  UG: { id: 'UG_Program', name: "UNDER GRADUATE"},
  PG: {id: 'PG_Program', name: "POST GRADUATE"}
}

export const PROGRAM_DROPDOWN = [
  {
    title: "BACHELOR OF COMPUTER APPLICATIONS",
    programType: "UG",
    id: "241",
  },
  {
    programType: "UG",
    title: "BCA WITH SPECIALISATION IN DATA ENGINEERING",
    id: "241",
  },
  {
    programType: "UG",
    title: "BCA WITH SPECIALISATION IN SOFTWARE ENGINEERING",
    id: "241",
  },
  {
    title: "BACHELOR OF BUSINESS ADMINISTRATION",
    programType: "UG",
    id: "240",
  },
  {
    programType: "UG",
    title: "BBA WITH SPECIALISATION IN DATA ANALYTICS",
    id: "240",
  },
  {
    title: "BACHELOR OF COMMERCE",
    programType: "UG",
    id: "243",
  },
  {
    title: "BACHELOR OF ARTS (JOURNALISM AND MASS COMMUNICATION)",
    programType: "UG",
    id: "242",
  },
  { programType: "UG", title: "BACHELOR OF ARTS", id: "244" },
  { programType: "UG", title: "BACHELOR OF ARTS (HINDI MEDIUM)", id: "492" },
  {
    programType: "UG",
    title: "BACHELOR OF ARTS (GENERAL) HINDI MEDIUM",
    id: "492",
  },
  {
    programType: "UG",
    title: "BACHELOR OF ARTS (GENERAL) IN TELUGU",
    id: "599",
  },
  {
    programType: "UG",
    title: "BACHELOR OF ARTS (GENERAL) IN TAMIL",
    id: "598",
  },
  {
    programType: "UG",
    title: "BACHELOR OF ARTS (GENERAL) IN MALAYALAM",
    id: "601",
  },
  {
    programType: "UG",
    title: "BACHELOR OF ARTS (GENERAL) IN KANNADA",
    id: "600",
  },
  {
    programType: "UG",
    title: "BACHELOR OF COMMERCE (GENERAL) HINDI MEDIUM",
    id: "491",
  },
  { programType: "PG", title: "MASTER OF BUSINESS ADMINISTRATION", id: "440" },
  {
    programType: "PG",
    title: "MASTER OF COMMERCE (FINANCIAL MANAGEMENT)",
    id: "245",
  },
  {
    programType: "PG",
    title: "MASTER OF ARTS (JOURNALISM AND MASS COMMUNICATION)",
    id: "246",
  },
  { programType: "PG", title: "MASTER OF COMPUTER APPLICATIONS", id: "515" },
  {
    programType: "PG",
    title: "MASTER OF ARTS (PUBLIC POLICY & GOVERNANCE)",
    id: "608",
  },
  {
    programType: "PG",
    title: "MCA WITH SPECIALISATION IN CYBER SECURITY",
    id: "515",
  },
  {
    programType: "PG",
    title: "MCA WITH SPECIALISATION IN SOFTWARE ENGINEERING",
    id: "515",
  },
  {
    programType: "UG",
    title: "BACHELOR OF COMMERCE (HONOURS)",
    id: "612",
  },
  {
    programType: "PG",
    title: "MASTER OF SCIENCE IN DATA SCIENCE",
    id: "613",
  },
  { 
    programType: "PG",
    title: "MASTER OF ARTS - PSYCHOLOGY",
    id: "617",
  }
];

export const PROGRAMS: any[] = [
  {
    title: "Bachelor of Business Administration (BBA)",
    url: "/bachelor-of-business-administration",
    category: "Management",
    specilisation: "",
    year: 3,
    programType: "UG",
    icon: "bba.svg",
  },
  {
    title: "Master of Business Administration (MBA)",
    url: "/master-of-business-administration-online",
    category: "Management",
    specilisation: "MBA",
    year: 2,
    programType: "PG",
    icon: "mba.svg",
  },
  {
    title: "MBA with specialization in Digital Marketing Management",
    url: "/mba-digital-marketing-management-online",
    category: "Management",
    specilisation: "MBA",
    year: 2,
    programType: "PG",
    icon: "mba-digital-marketing.svg",
  },
  {
    title: "MBA with specialization in HR Analytics",
    url: "/mba-human-resources-hr-analytics-online",
    category: "Management",
    specilisation: "MBA",
    year: 2,
    programType: "PG",
    icon: "mba-hr.svg",
  },
  {
    title: "MBA with specialization in Data Science",
    url: "/mba-data-science-online",
    category: "Management",
    specilisation: "MBA",
    year: 2,
    programType: "PG",
    icon: "mba-data-science.svg",
  },
  {
    title: "MBA with specialization in Business Analytics",
    url: "/mba-business-analytics-online",
    category: "Management",
    specilisation: "MBA",
    year: 2,
    programType: "PG",
    icon: "mba-business-analytics.svg",
  },
  {
    title: "MBA with specialization in Digital Entrepreneurship",
    url: "/mba-digital-entrepreneurship-online",
    category: "Management",
    specilisation: "MBA",
    year: 2,
    programType: "PG",
    icon: "mba-digital-entrepreneurship.png",
  },
  {
    title: "Bachelor of Computer Applications (BCA)",
    url: "/bachelor-of-computer-applications-online",
    category: "Information Technology",
    specilisation: "BCA",
    year: 3,
    programType: "UG",
    icon: "bca.svg",
  },
  {
    title: "BCA with specialization in Cloud & Security",
    url: "/bca-cloud-security-online",
    category: "Information Technology",
    specilisation: "BCA",
    year: 3,
    programType: "UG",
    icon: "bca-cloud.png",
  },
  {
    title: "BCA with specialization in Data Analytics",
    url: "/bca-data-analytics-online",
    category: "Information Technology",
    specilisation: "BCA",
    year: 3,
    programType: "UG",
    icon: "bba.svg",
  },
  {
    title: "Master of Computer Applications (MCA)",
    url: "/master-of-computer-applications-online",
    category: "Information Technology",
    specilisation: "MCA",
    year: 2,
    programType: "PG",
    icon: "mca.svg",
  },
  {
    title: "MCA with specialization in Blockchain",
    url: "/mca-blockchain-online",
    category: "Information Technology",
    specilisation: "MCA",
    year: 2,
    programType: "PG",
    icon: "mca-blockchain.svg",
  },
  {
    title: "MCA with specialization in ML & AI",
    url: "/mca-machine-learning-and-artificial-intelligence-online",
    category: "Information Technology",
    specilisation: "MCA",
    year: 2,
    programType: "PG",
    trending: "Machine Learning",
    icon: "mca-ai-ml.svg",
  },
  {
    title: "MCA with specialization in Machine Learning",
    url: "/mca-machine-learning-online",
    category: "Information Technology",
    specilisation: "MCA",
    year: 2,
    programType: "PG",
    trending: "Machine Learning",
    icon: "mca-ml.svg",
  },
  {
    title: "MCA with specialization in AR & VR",
    url: "/mca-virtualreality-online",
    category: "Information Technology",
    specilisation: "MCA",
    year: 2,
    programType: "PG",
    trending: "AR & VR",
    icon: "mca-ar-vr.svg",
  },
  {
    title: "Master of Arts (Public Policy & Governance) (MA)",
    url: "/ma-public-policy",
    category: "Arts & Humanities",
    year: 2,
    specilisation: "MA",
    programType: "PG",
    icon: "mba-business-analytics.svg",
  },
  {
    title: "Bachelor of Arts (BA)",
    url: "/bachelor-of-arts-online",
    category: "Arts & Humanities",
    specilisation: "BA",
    year: 3,
    programType: "UG",
    icon: "ba.png",
  },
  {
    title: "Bachelor of Arts (Kannada Medium) (BA)",
    url: "/bachelor-of-arts-kannada-online",
    category: "Arts & Humanities",
    specilisation: "BA",
    year: 3,
    programType: "UG",
    icon: "ba-kannada.png",
  },
  {
    title: "Bachelor of Arts (Malayalam Medium) (BA)",
    url: "/bachelor-of-arts-malayalam-online",
    category: "Arts & Humanities",
    specilisation: "BA",
    year: 3,
    programType: "UG",
    icon: "ba-malyalam.png",
  },
  {
    title: "Bachelor of Arts (Tamil Medium) (BA)",
    url: "/bachelor-of-arts-tamil-online",
    category: "Arts & Humanities",
    specilisation: "BA",
    year: 3,
    programType: "UG",
    icon: "ba-tamil.png",
  },
  {
    title: "Bachelor of Arts (Telugu Medium) (BA)",
    url: "/bachelor-of-arts-telugu-online",
    category: "Arts & Humanities",
    specilisation: "BA",
    year: 3,
    programType: "UG",
    icon: "ba-tamil.png",
  },
  {
    title: "Bachelor of Arts (Hindi Medium) (BA)",
    url: "/bachelor-of-arts-hindi-medium-online",
    category: "Arts & Humanities",
    specilisation: "BA",
    year: 3,
    programType: "UG",
    icon: "ba-tamil.png",
  },
  {
    title: "Bachelor of Commerce (BCOM)",
    url: "/bachelor-of-commerce-online",
    category: "Commerce",
    year: 3,
    programType: "UG",
    icon: "boc.svg",
  },
  // {
  //   title: "BCOM with specialization in International Finance & Accounting",
  //   url: "/lp/bcom-internationalfinance",
  //   category: "Commerce",
  //   year: 3,
  //   programType: "UG",
  //   icon: "mba-digital-entrepreneurship.png",
  // },
  {
    title: "Master of Commerce (Financial Management) (MCOM)",
    url: "/master-of-commerce-financial-management-online",
    category: "Commerce",
    year: 2,
    programType: "PG",
    icon: "mba-finance.svg",
  },
  {
    title: "MCOM with specialization in Fintech",
    url: "/mcom-fintech-online",
    category: "Commerce",
    year: 2,
    programType: "PG",
    icon: "mba-business-analytics.svg",
  },
  {
    title: "Bachelor of Arts (Journalism And Mass Communication) (BA)",
    url: "/bachelor-of-arts-journalism-and-mass-communication-online",
    category: "Media & Journalism",
    specilisation: "BA",
    year: 3,
    programType: "UG",
    icon: "ba-journalism.svg",
  },
  {
    title: "BCOM with Specialization in International Finance & Accounting",
    url: "https://amityonline.com/lp/bcom-internationalfinance/",
    category: "Commerce",
    specilisation: "Commerce",
    year: 3,
    programType: "UG",
    icon: "bcom-finance.svg",
  },
  {
    title: "MBA with Specialization in International Finance",
    url: "https://amityonline.com/lp/mba-internationalfinance/",
    category: "Management",
    specilisation: "BA",
    year: 2,
    programType: "PG",
    icon: "mba-finance.svg",
  },
  {
    title: "Master of Arts (Journalism And Mass Communication) (MA)",
    url: "/master-of-arts-journalism-and-mass-communication-online",
    category: "Media & Journalism",
    year: 2,
    programType: "PG",
    icon: "ma-journalism.svg",
  },
  // {
  //   title: "MCOM with Specialization in Financial Management",
  //   url: "/master-of-commerce-financial-management-online",
  //   category: "Commerce",
  //   specilisation: "Commerce",
  //   year: 2,
  //   programType: "PG",
  //   icon: "bcom-finance.svg",
  // },
  {
    title: "Certificate in Machine Learning and Generative AI",
    url: "https://amityonline.com/certificate-in-ml-with-gai/",
    programType: "Certifications",
    trending: "Artificial Intelligence",
    icon: "mca-ai-ml.svg",
  },
];

const PROGRAM_MENU: any = {
  "PROGRAM WISE": {},
  "DOMAIN WISE": {},
  "Trending courses": {},
};

// PROGRAMS.forEach((program: any) => {
//   const category = program.category;
//   const pgType = program.programType;
//   const specilisation = program.specilisation;
//   const trending = program.trending;

//   if (!PROGRAM_MENU["DOMAIN WISE"][category]) {
//     PROGRAM_MENU["DOMAIN WISE"][category] = [];
//   }
//   PROGRAM_MENU["DOMAIN WISE"][category].push(program);

//   if (!PROGRAM_MENU["PROGRAM WISE"][pgType]) {
//     PROGRAM_MENU["PROGRAM WISE"][pgType] = [];
//   }

//   PROGRAM_MENU["PROGRAM WISE"][pgType].push(program);

//   // if (specilisation) {
//   //   if (!PROGRAM_MENU["Trending courses"][specilisation]) {
//   //     PROGRAM_MENU["Trending courses"][specilisation] = [];
//   //   }

//   //   PROGRAM_MENU["Trending courses"][specilisation].push(program);
//   // }

//   if (trending) {
//     if (!PROGRAM_MENU["Trending courses"][trending]) {
//       PROGRAM_MENU["Trending courses"][trending] = [];
//     }

//     PROGRAM_MENU["Trending courses"][trending].push(program);
//   }
// });

export const PROGRAM_MENU_LIST = PROGRAMS.reduce(
  (PROGRAM_MENU, program) => {
    const category = program.category;
    const pgType = program.programType;
    const specilisation = program.specilisation;
    const trending = program.trending;

    if (!!category) {
      if (!PROGRAM_MENU["DOMAIN WISE"][category]) {
        PROGRAM_MENU["DOMAIN WISE"][category] = [];
      }
      PROGRAM_MENU["DOMAIN WISE"][category].push(program);
    }

    if (!!pgType) {
      if (!PROGRAM_MENU["PROGRAM WISE"][pgType]) {
        PROGRAM_MENU["PROGRAM WISE"][pgType] = [];
      }

      PROGRAM_MENU["PROGRAM WISE"][pgType].push(program);
    }

    // if (specilisation) {
    //   if (!PROGRAM_MENU["Trending courses"][specilisation]) {
    //     PROGRAM_MENU["Trending courses"][specilisation] = [];
    //   }

    //   PROGRAM_MENU["Trending courses"][specilisation].push(program);
    // }

    if (!!trending) {
      if (!PROGRAM_MENU["Trending courses"][trending]) {
        PROGRAM_MENU["Trending courses"][trending] = [];
      }
      PROGRAM_MENU["Trending courses"][trending].push(program);
    }

    return PROGRAM_MENU;
  },
  {
    "PROGRAM WISE": {},
    "DOMAIN WISE": {},
    "Trending courses": {},
  },
);

export const searchPrograms = (query: string, programs: any = PROGRAMS) => {
  query = query.toLowerCase();
  const results: any = [];

  for (const program of programs) {
    if (program?.Title?.toLowerCase()?.includes(query)) {
      results.push(program);
    }
  }

  return results;
};
