"use client";

import { useCallback, useState, useEffect } from "react";
import { Field, Form } from "react-final-form";
import cn from "clsx";
import { pushToDataLayer } from "@/helpers/gtag";
import { getGlobalStateValue } from "@/context/utils";


const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export default function NewsletterForm({ data, styles }) {
  const [emailError, setEmailError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (val, form) => {

      setMessage("");
      setEmailError("");
      let timeout: NodeJS.Timeout;
      
      if (!EMAIL_REGEX.test(val.email)) {
        pushToDataLayer({
          event: "newsletter_info_fill",
          field_name: "Email Id",
          error_message: "Please enter valid email",
          section_name: "Newsletter",
          page_type: getGlobalStateValue('pageType'),
        });

        return { email: "Please enter valid email" };
      }

      try {
        pushToDataLayer({
          event : "newsletter_subscribe",
          cta_text : "Subscribe",
          page_type : getGlobalStateValue('pageType'),
          section_name : "footer"
        })
        const email = val.email;
        setLoading(true);

        const res = await fetch(`/api/newsletter?email=${email}`);
        const data = await res.json();

      
        if (data?.data?.newsletters?.data?.length > 0) {
          setEmailError("You are already subscribed!");
          return;
        }


        const postRes = await fetch(`/api/newsletter`, {
          method: "POST",
          body: JSON.stringify({ email }),
          headers: {
            "Content-Type": "application/json",
          },
        });

        const postData = await postRes.json();
        if (postData?.success) {
          setMessage("Thank you for subscribing!");

        } else {
          setEmailError("Something went wrong! Please try again later");
        }
      } catch (e) {
        setEmailError("Something went wrong! Please try again later");
      } finally {
        setLoading(false);
        timeout = setTimeout(() => {
          setMessage("");
          setEmailError("");
          clearTimeout(timeout);
        }, 3000);
        form.restart();
        form.resetFieldState("email");
      }
    },
    [message, emailError, loading]
  );

  const eventHandlerOnBlur = (error) => { 
    pushToDataLayer({
      event:'newsletter_info_fill',
       field_name: "Email Id",
       error_message: error || "NA",
       section_name: "Newsletter",
       page_type: getGlobalStateValue('pageType'),
    })
  }

  const validate = (values) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const errors: any = {};
    if (!emailRegex.test(values.email) && values.email) {
      errors.email = "Please enter valid email";
    }

    return errors;
  };

  return (
    <Form onSubmit={handleSubmit} >
      {(props) => (
        <>
          <form
            onSubmit={props.handleSubmit}
            className={cn("mt-[22px] basis-full	relative", styles.form)}
          >
            {data?.EmailPlaceholder?.[0]?.enabled && (
              <Field name="email" type="text">
                {({ input, meta }) => (
                  <>
                    <input
                      {...input}
                      onBlur={()=> eventHandlerOnBlur(meta.error || meta.submitError)}
                      type={input.type}
                      name={input.name}
                      placeholder={data?.EmailPlaceholder?.[0]?.text}
                      autoComplete="on"
                      className={cn(
                        "text-zinc-600 font-raleway border border-primary-blue rounded-full w-full outline-none lining-nums placeholder:text-gray-400",
                        styles.newsletterInput
                      )}
                    />
                     <p className={styles.emailError}>
                    {meta.error || meta.submitError || meta.touched && (
                        <span>{meta.error || meta.submitError}</span>)
                    }
                    </p>
                  </>
                )}
              </Field>
            )}

            {data?.NewsLetterFormCTA?.enabled ? (
              <button
                type="submit"
                className={cn(
                  "bg-primary-blue absolute top-1/2 right-1 transform -translate-y-1/2",
                  styles.newsletterButton
                )}
                disabled={loading || props.hasValidationErrors}
              >
                {loading ? "Submitting..." : data?.NewsLetterFormCTA?.text}
              </button>
            ) : null}
          </form>

          {message && <p className={styles.message}>{message}</p>}
        </>
      )}
    </Form>
  );
}
