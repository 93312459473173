"use client";

import {Fragment, useState } from "react";
import styles from "./styles.module.scss";
import cn from "clsx";
import Button from "@/components/atoms/Button";
import { pushToDataLayer } from "@/helpers/gtag";
import { getGlobalStateValue } from "@/context/utils";

export default function FooterLinks({
  footerLinks,
}: {
  footerLinks: {
    footerLinksOne: any;
    footerLinksTwo: any;
    footerLinksThree: any;
  };
}) {
  const [showList, setShowList] = useState(false);
 
  const toggleList = () => {
    setShowList(!showList);
    
    pushToDataLayer({
      event: "generic_cta_click",
      cta_text: "Show More",
      section_name: "footer",
      page_type: getGlobalStateValue('pageType'),
    });
  };

  const handleGaClick = (itemData:any) => { 
    const {ctaText="", sectionText="" } = itemData || {};
    pushToDataLayer({
      event : "footer_interaction",
      cta_text : ctaText || "NA",
      section_name: sectionText || "NA",
      page_type : getGlobalStateValue('pageType')
    })

    
  }

  return (
    <>
      <div
        className={cn(
          "w-full flex justify-center items-center",
          styles.readmoreCTA
        )}
      >
        <Button
          buttonProps={{ onClick: toggleList }}
          type="submit"
          className={cn(
            `headFootSprite ${showList ? styles.showLessArrow : styles.showMoreArrow}`,
          )}
          bg="o"
          color="b"
          aria-label={showList ? "show less" : "show more"}
        >
          {showList ? "show less" : "show more"}
        </Button>
      </div>
      <div
        className={cn(styles.hiddenFooterLinks, {
          [styles.showFooterLinks]: showList,
        })}
      >
        <div
          className={cn(
            "flex flex-col my-8 lg:grid lg:grid-rows-1 lg:grid-cols-3",
            styles.hiddenMenuContainer
          )}
        >
          <ul className={cn(styles.menu1, "col-span-1")}>
            {footerLinks.footerLinksOne
              ?.filter((x: any) => x.enabled)
              ?.map((link: any, index: number) => (
                <Fragment key={index}>
                    <li
                      className={cn(
                        styles.headingLink,
                        index > 0 &&
                          link?.SubItem?.length &&
                          styles.headingLinkWithChild,"w-fit"
                      )}
                    >
                        <a href={link.Item.url} onClick={(e)=>{
                          handleGaClick({
                              sectionText : link?.Item?.text,
                              ctaText : link?.Item?.text || "NA"
                            });
                          }} 
                          target={link?.Item?.target || "_self"}
                        >
                          {link.Item.text}
                        </a>
                    </li>
                  {link?.SubItem?.map((subLink: any, index: number) => (
                    <li className={cn(styles.subMenuItem,'w-fit')} key={index}>
                      <a 
                        href={subLink?.url}
                        onClick={(e)=>{
                          handleGaClick({
                            sectionText : link?.Item?.text,
                            ctaText : subLink?.text || "NA"
                          });
                        }} 
                        target={subLink?.target || "_self"}>
                        {subLink?.text}
                      </a>
                    </li>
                  ))}
                </Fragment>
              ))}
          </ul>
          <ul className="col-span-1 w-fit">
            {footerLinks.footerLinksTwo
              ?.filter((x: any) => x.enabled)
              ?.map((link: any, index: number) => (
                <Fragment key={index}>
                  <li
                    className={cn(
                      styles.headingLink,
                      index > 0 &&
                        link?.SubItem?.length &&
                        styles.headingLinkWithChild
                    )}
                  >
                    {link.Item.text}
                  </li>
                  {link?.SubItem?.map((subLink: any, index: number) => (
                    <li className={cn(styles.subMenuItem, "w-fit")} key={index}>
                      <a href={subLink?.url} target={subLink?.target || "_self"}
                        onClick={(e)=>
                          handleGaClick({
                            sectionText : link?.Item?.text || "NA",
                            ctaText : subLink?.text || "NA"
                          })
                        } 
                      >
                        {subLink?.text}
                      </a>
                    </li>
                  ))}
                </Fragment>
              ))}
          </ul>

          <ul className="col-span-1 w-fit">
            {footerLinks.footerLinksThree
              ?.filter((x: any) => x.enabled)
              ?.map((link: any, index: number) => (
                <Fragment key={index}>
                  <li
                    className={cn(
                      styles.headingLink,
                      index > 0 &&
                        link?.SubItem?.length &&
                        styles.headingLinkWithChild
                    )}
                    key={index}
                  >
                    {link.Item.text}
                  </li>
                  {link?.SubItem?.map((subLink: any, index: number) => (
                    <li className={cn(styles.subMenuItem, "w-fit")} key={index}>
                      <a href={subLink?.url} target={subLink?.target || "_self"}
                        onClick={(e)=>
                          handleGaClick({
                            sectionText : link?.Item?.text || "NA",
                            ctaText : subLink?.text || "NA"
                          })
                        }
                      >
                        {subLink?.text}
                      </a>
                    </li>
                  ))}
                </Fragment>
              ))}
          </ul>
        </div>

        <hr className="text-[#D4D4D4] stroke-[1.5px]" />
      </div>
    </>
  );
}
